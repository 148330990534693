
.time-week-container {
    background-color: #fff;
    padding: 8px;
    margin: 0px;
    flex-wrap: wrap-reverse;
    border-radius: 11px;
}

.time-week-main-container{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 11px;
}

.week-day-checkbox-container > .form-check-input:checked {
    background-color:#3B99FC;
    border-color:#3B99FC;
}
.week-day-checkbox-container > .form-check-input {
   cursor: pointer;
}

.time-week-container .col{
 display: flex;
}

.time-box-content {
    display: flex;
    width: 115px;
    margin-right: 12px;
}
.time-box-container {
    display: flex;
    justify-content: start;
}

.time-box-content > .time-slot-box {
    border-top: 1px solid #6c757db0;
    height: 25px;
    width: 90px;
    margin-right: 5px;
}

.time-increment-decrement {
    height: 25px;
    width: 12px;
    border: thin solid #adb5bd;
    border-radius: 6px;overflow: hidden;
}

.time-increment-decrement .increment-icon-container .time-increment {
    height: 100%;
    width: 100%;
}

.time-increment-decrement .increment-icon-container {
    cursor: pointer;
    height: 50%;
    width: 100%;
    display: flex;
    padding: 2px;
}

.time-increment-decrement .increment-icon-container:hover {
    background-color: #e9ecef;
}

.time-week-main-container > .col-1 > .btn-close-icon {
    height: 17px;
    width: 17px;
    cursor: pointer;
}

.time-increment-decrement .decrement-icon-container .time-decrement {
    height: 100%;
    width: 100%;
}

.time-increment-decrement .decrement-icon-container {
    cursor: pointer;
    height: 50%;
    width: 100%;
    display: flex;
    padding: 2px;
}

.time-increment-decrement .decrement-icon-container:hover {
  background-color: #e9ecef;
}

.week-day-checkbox-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
}

.time-slot-box .txt-hours{
    height: 25px;
    width: 22px;
    border: 0px;
    font-size: 15px;
}
.time-slot-box .txt-minutes{
    height: 25px;
    width: 22px;
    border: 0px;
    font-size: 15px;
}


.time-slot-box > [class *= txt-]::-webkit-outer-spin-button,
.time-slot-box > [class *= txt-]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media only screen and (min-width: 992px) and (max-width: 1400px) {
    .week-day-checkbox-container{
        margin-bottom: 12px;
    }
    .time-box-container {
        justify-content: space-evenly;
    }
  }
  
  @media only screen and (max-width:768px) {
    .week-day-checkbox-container{
        margin-bottom: 12px;
    }
    .time-box-container {
        justify-content: space-evenly;
    }
  }