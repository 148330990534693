.location-container {
    background-color: #fff;
    border-radius: 9px;
    overflow: hidden;
}

@media only screen and (min-width: 992px) {
    .location-container .selected {
        background-color: #E5E4E9;
    }
}
.location-container .location-item:active {
    background-color: #E5E4E9;
}

.location-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-left:16px;
}

.location-icon {
    background-color: #F1EBE5;
    height: 22px;
    width: 22px;
}

.location-title-container {
    border-bottom: thin solid #C7C7CC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-Left: 10px;
    flex-grow: 1;
    padding: 15px 0px;
}
.location-title-container .location-item-title {
    line-height: 14px;
}
