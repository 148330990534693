.signin-container > .row{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.signin-container > .row > .signin-card-onBoarding{
    box-shadow: 4px 12px 20px #eee, -4px -6px 20px #eee;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 30px;
}
.signin-card-onBoarding > .we-cal-title{
    font-family: robotoBold;
    font-size: 25px;
    margin-bottom: 25px;
}

.signin-brand-logo{
    height:50px;
}

.signin-card-onBoarding > .signin-button {
    background-color: #fff;
    border-radius: 6px;
    justify-content: center;
    padding: 8px 12px;
    position: relative;
    align-items: center;
    cursor: pointer;
    display: flex;
    border: 2px solid #acb1b9;
    width: 100%;
}
.signin-button .signin-label{
    font-size: 16px;
    cursor: pointer;
    color: #787d86;
}
.signin-button .google-logo{
    height: 22px;
    width: 22px;
}

.signin-card-onBoarding > .signin-button {
    background-color: #fff;
    border-radius: 6px;
    justify-content: center;
    padding: 8px 12px;
    position: relative;
    align-items: center;
    cursor: pointer;
    display: flex;
    border: 2px solid #acb1b9;
    width: 100%;
}

.signin-container > .row > .signin-card-onBoarding {
    box-shadow: 4px 12px 20px #eee, -4px -6px 20px #eee;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 28px;
    border-radius: 10px;
    background-color: #fff;
    height: auto;
    max-width: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: space-between;
}