.personal-link-item-container {
  background-color: #fff;
  border-radius: 9px;
}

.personal-link-item .btn-edit {
  background: #F2F2F2;
  padding-right: 15px;
  padding-top: 3px;
  padding-left: 15px;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
}

.txt-update-link{
  font-size: 18px;
  border: 1px solid#D1D1D1;
  background-color: #F5F5F5;
  border-radius: 5px;
}

.update-link-modal-title {
  white-space: nowrap;
}

.update-link-modal .modal-content {
  padding: 16px;
  border-radius: 10px;
}

.update-link-modal-footer> .btn-cancel {
  padding: 1px 33px;
  background: #F7F7F7;
  color: #797979;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 18px;
}

.update-link-modal-footer {
  flex-wrap: nowrap;
}

 .update-link-modal-footer > .btn-update {
  padding: 1px 34px;
  background: #017AFF;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 18px;
}

 .update-link-modal-footer > .btn-update:disabled {
  background: #017cffab;
  cursor: no-drop;
}

.update-modal-link-error {
  font-size: 12px;
}

.update-link-modal-container.show .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: auto !important;
  height: 100%;

}

@media only screen and (max-width: 576px) {
  .update-link-modal-container.show .modal-dialog {
    margin: 0px 20px !important;
  }
}

@media only screen and (max-width: 992px) {

  .update-link-modal-footer> .btn-update {
    padding: 1px 28px;
    font-size: 14px;
    border-radius: 5px;
  }

  .update-link-modal-footer> .btn-cancel {
    padding: 1px 29px;
    font-size: 14px;
    border-radius: 5px;
  }
}