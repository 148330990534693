.calender-date {
    padding: 5px !important;
    font-size: 12px;
    background: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.calender-date:disabled>abbr {
    color: #cdccd2 !important;
}

.react-calendar__navigation>.react-calendar__navigation__label:disabled {
    background-color: #fff !important;
}

.calendar {
    font-family: robotoBold !important;
    padding: 5px;
    width: 100% !important;
}

.calendar-container{
    position: relative;
}

.calendar .react-calendar__navigation__prev2-button {
    display: none;
}

.calendar .react-calendar__navigation__next2-button {
    display: none;
}

.calendar .react-calendar__navigation__next-button {
    order: 1;
    min-width: 35px !important;
    height: 35px;
}

.calendar .react-calendar__navigation__next-button img {
    transform: rotate(180deg);
}

.calendar .react-calendar__navigation__prev-button {
    order: 1;
    min-width: 35px !important;
    height: 35px;
}

.react-calendar__navigation button {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-calendar__navigation button .react-calendar__navigation__label__labelText div span {
    color: #000000;
}

.react-calendar__navigation {
    margin-bottom: 0px !important;
    height: 33px !important;
}

.calendar .react-calendar__navigation__label {
    font-size: 14px;
    font-family: robotoBold;
    display: flex;
    justify-content: start;
}

.calendar .react-calendar__navigation__label div img {
    transform: rotate(180deg);
    height: 10px;
    width: 10px;
    margin-left: 2px;

}

.calendar .react-calendar__navigation__label div {
    display: flex;
    align-items: center;
}

.calendar abbr {
    text-decoration: none;
    color: #C4C4C6;
}

.react-calendar__month-view__weekdays {
    font-size: 11px !important;
}

.calendar .react-calendar__month-view__days abbr {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 14px;
    color: #41464b;
}

.calendar .react-calendar__tile--active abbr {
    color: #488EF7;
}

.bg-mark abbr {
    background-color: #E0EFFF !important;
    color: #027BFF !important;
}

.bg-active-mark abbr {
    background-color: #488EF7 !important;
    color: #fff !important;
}

.calendar-navigation-icon {
    height: 14px;
    width: 14px;
}

.select-date-title {
    font-family: robotoBold;
    font-size: 25px;
}
.add-calendar-combine-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-calendar-combine-container > p {
   font-size: 14px;
}

.add-calendar-combine-container > .btn-save {
    background: #e5e5ea;
    border-radius: 6px;
    font-size: 15px;
    border: 0px;
    color: #0a0afe;
    padding: 10px;
    border: 0px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.public-calendar-header-loader{
    position: absolute;
    top:0px;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}