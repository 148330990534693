.button-logout-user {
    padding: 6px 45px;
    background-color: #E5E5EA;
    font-size: 18px;
    color: #C70000;
    border-radius: 10px;
    border: 0px;
    margin-bottom: 10px;
    white-space: nowrap;
}

.profile-details-item {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.profile-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
@media only screen and (min-width: 992px) {
    .profile-details-container .selected {
        background-color: #E5E4E9;
    }
}
.profile-details-container .profile-details:active {
    background-color: #E5E4E9;
}

.profile-details {
    background-color: #fff;
    border-radius: 9px;
    position: relative;
    display: flex;
}

.profile-details .time-zone-title {
    white-space: nowrap;
    margin-right: 3px;
}

.profile-details-icon {
    background-color: #F1F5F9;
}

.profile-details-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-Left: 10px;
    flex-grow: 1;
    width: calc(100% - 36px);
}

.profile-details-title-container .personal-link-title {
    white-space: nowrap;
}

.profile-details-title-container .main-calendar-link-container {
    width: calc(100% - 100px);
}

@media only screen and (max-width: 992px) {
    .profile-details-item {
        cursor: pointer;
    }
}
