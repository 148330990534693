.new-calendar-container {
    background-color: #fff;
    border-radius: 9px;
}

.new-calendar-item {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.new-calendar-icon {
    background-color: #F1EBE5;
    height: 26px;
    width: 26px;
}

.add-calendar-icon{
    height: 12px;
    width: 12px;
}
.new-calendar-item-title {
    font-size: 16px;
}

.new-calendar-item .new-calendar-title {
    padding: 12px 0;
    border-bottom: thin solid #C7C7CC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-Left: 10px;
    flex-grow: 1;
}

.meeting-info-tooltip .tooltip .tooltip-inner {
    background-color: #F4F5F5;
    color: #000000;
    font-size: 12px;
    padding: 9px 11px;
    border-radius: 6px;
    max-width: 250px;
}

.meeting-info-tooltip .tooltip.show {
    opacity: 1;
}

.meeting-info-tooltip .tooltip .tooltip-arrow::before {
    border-bottom-color: #F4F5F5;
}