.re-connect-modal .modal-content {
    padding: 16px;
    border-radius: 10px;
  }
  
  .re-connect-modal-footer> .btn-cancel {
    padding: 1px 33px;
    background: #F7F7F7;
    color: #797979;
    border: 1px solid transparent;
    border-radius: 6px;
    font-size: 18px;
  }
  
  .re-connect-modal-footer {
    flex-wrap: nowrap;
  }
  
  .re-connect-modal .modal-content .re-connect-modal-title {
    font-size: 18px;
    color: #8F8F8F;
}

   .re-connect-modal-footer > .btn-update {
    padding: 1px 34px;
    background: #017AFF;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 6px;
    font-size: 18px;
  }
  
   .re-connect-modal-footer > .btn-update:disabled {
    background: #017cffab;
    cursor: no-drop;
  }
  
  .update-modal-link-error {
    font-size: 12px;
  }
  
  .re-connect-modal-container.show .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin: auto !important;
    height: 100%;
  
  }
  
  @media only screen and (max-width: 576px) {
    .re-connect-modal-container.show .modal-dialog {
      margin: 0px 20px !important;
    }
  }
  
  @media only screen and (max-width: 992px) {
  
    .re-connect-modal-footer> .btn-update {
      padding: 1px 28px;
      font-size: 14px;
      border-radius: 5px;
    }
  
    .re-connect-modal-footer> .btn-cancel {
      padding: 1px 29px;
      font-size: 14px;
      border-radius: 5px;
    }
  }