.participants-label {
    color: darkgray;
    font-family: robotoBold;
}

.participants-main-container{
    display: flex;
    flex-direction: column;
    height:100%;
    justify-content: space-between;
}

.participants-dropdown {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 1px #ccc, -1px -1px 1px #ccc;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.participants-dropdown-icon-container {
    background-color: #52A5FC;
    background-color: #52A5FC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 100%;
    width: 30px;
}

.participants-dropdown-icon-container > img {
    height: 8px;
    width: 11px;
}

.participants-dropdown > div {
    justify-content: space-between;
    padding: 0px;
    border: 0px;
    width: 100%;
    overflow: auto;
    max-height: 315px;
    height: auto;
    cursor: pointer;
    font-size: 15px;
    height: 34px;
    align-items: center;
}

.participants-dropdown>.dropdown-menu>.dropdown-item:hover {
    background-color: #e5e5ea;
}

.participants-dropdown>.dropdown-menu>.dropdown-item:active {
    background-color: #e5e5ea;
    color: black;
}

.participants-dropdown>.dropdown-menu>[class *="dropdown-"] {
    padding: 0rem;
    font-family: robotoNormal;
    padding-left: 4px;
    background-color: #fff;
}
.participants-dropdown>.dropdown-menu{
    min-width: 250px;
    padding: 0px;
    border-radius: 0px;
    height: auto;
    max-height: 227px;
}
