.set-url-container > .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.availability-text{
    font-size: 14px;
}
.availability-text > .bold{
    font-family: robotoBold;
}

.set-url-container > .row > .set-url-card {
    box-shadow: 4px 12px 20px #eee, -4px -6px 20px #eee;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding:20px 28px 20px 28px;
    border-radius: 10px;
    background-color: #fff;
    height: 95%;
    max-width: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: space-between;
}

.set-url-card > .we-cal-title {
    font-family: robotoBold;
    font-size: 30px;
    margin-bottom: 10px;
}

.set-url-card > .we-cal-desc {
    font-family: robotoNormal;
    font-size: 16px;
    margin-bottom: 20px;
}

.set-url-card > .update-modal-link-error {
    font-family: robotoBold;
    font-size: 13px;
    margin-bottom: 25px;
    width: 100%;
}

.set-url-card > .signup-label {
    font-family: robotoBold;
    color: #787d86;
    font-size: 15px;
    align-self: flex-start;
}

.editable-button-container > .btn-save:disabled {
    color: #a4a4ad;
}

.set-url-card > div > .input-url-link {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #c6c5c7;
    border-radius: 0;
}

.set-url-card > div > .url {
    font-family: robotoBold;
    font-size: 16px;
}

.set-url-card > div .signup-terms {
    font-family: robotoNormal;
    font-size: 14px;
    margin-left: 12px;
}

.set-url-brand-logo {
    height: 81px;
}

.set-url-card > .signin-button {
    background-color: #fff;
    border-radius: 6px;
    justify-content: center;
    padding: 8px 12px;
    position: relative;
    align-items: center;
    cursor: pointer;
    display: flex;
    border: 2px solid #acb1b9;
    width: 100%;
}

.signin-button .signin-label {
    font-size: 16px;
    cursor: pointer;
    color: #787d86;
}

.signin-button .google-logo {
    height: 22px;
    width: 22px;
}

.calendar-link-error {
    width: 100%;
    color: #c70000;
    font-size: 13px;
    font-family: robotoBold;
}

.dropdown-icon-container-onBoarding {
    background: linear-gradient(to bottom, #65affa, #0e81ff);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 100%;
    width: 22px;
}

.onBoarding-dropdown > .dropdown-menu > .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #3d3d3d;
}

.onBoarding-dropdown > div {
    cursor: pointer;
    font-size: 15px;
    height: 20px;
    align-items: center;
}

.onBoarding-dropdown > div > span {
    line-height: 22px;
}

.onBoarding-dropdown {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 1px #ccc, -1px -1px 1px #ccc;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-radius: 0;
    height: 20px;
}

.onBoarding-dropdown > .dropdown-menu {
    width: 100%;
    padding: 0px;
    border-radius: 0px;
    height: auto;
}

.onBoarding-dropdown > .dropdown-menu > .dropdown-item:hover {
    background-color: #e5e5ea;
}

.onBoarding-dropdown > .dropdown-menu > .dropdown-item:active {
    background-color: #e5e5ea;
}

.onBoarding-dropdown > .dropdown-menu > [class*="dropdown-"] {
    padding: 0rem;
    font-family: robotoNormal;
    padding-left: 4px;
}

.info-icon {
    height: 13px;
    width: 13px;
    margin-left: 5px;
}

.onBoarding-button-container > .btn-save {
    background: #e5e5ea;
    border-radius: 6px;
    font-size: 15px;
    border: 0px;
    width: 100%;
    color: #0a0afe;
    padding: 10px;
    border: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

@media only screen and (max-width: 576px) {
    .onBoarding-button-container > .btn-save {
        width: 250px;
    }
    .onBoarding-button-container {
        display: flex;
        justify-content: center;
    }
}
