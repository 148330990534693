.public-page-container {
    height: 100vh;
    display: flex;
    background-color: #F2F3FF;
}

.public-page-container .react-reveal {
  padding: 0px;
  height:100%;
}

.public-page-panel{
    padding: 18px;
    height: 100%;
    overflow: auto;
    flex-direction: column;
}
