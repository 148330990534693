.new-element-container {
    background-color: #fff;
    border-radius: 9px;
}

.new-element-item {
    display: flex;
    text-decoration: none;
    color: black;
    align-items: center;
}

.new-element-icon {
    background-color: #F1EBE5;
}

.new-element-item-title {
    font-size: 16px;
}

.new-element-title {
    padding: 12px 0px;
    border-bottom: thin solid #C7C7CC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-Left: 10px;
    flex-grow: 1;
    cursor: pointer;
}