.button-preview-calendar {
    padding: 6px 45px;
    background-color: #E5E5EA;
    font-size: 18px;
    color: blue;
    border-radius: 10px;
    border: 0px;
    margin-bottom: 10px;
    white-space: nowrap;
    min-width: 230px;
}

@media only screen and (min-width: 992px) {
    .calendar-details-container > div .calendar-item-container .selected {
        background-color: #E5E4E9;
    }
}

.calendar-details-container > div .calendar-item-container .calendar-details-item:active {
    background-color: #E5E4E9;
}

.calendar-details-item {
    cursor: pointer;
    border-bottom: thin solid #C7C7CC;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.calendar-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 42px);
}

.calendar-item-container {
    background-color: #fff;
    border-radius: 9px;
    overflow: hidden;
}

.calendar-details-icon {
    background-color: #F1F5F9;
}

.calendar-details-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-Left: 10px;
    flex-grow: 1;
}

.txt-add-calendar {
    background-color: #E5E5EA;
    font-size: 18px;
}

.txt-add-calendar:focus {
    background-color: #E5E5EA;
    box-shadow: none;
}

.calendar-details-container .modal.fade {
    transform: none !important;
}

.coming-soon-item-title {
    text-transform: capitalize;
}

.coming-soon-title-container {
    border-bottom: thin solid #C7C7CC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-Left: 10px;
    flex-grow: 1;
    padding: 12px 0px;
}

.coming-soon-item {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.coming-soon-item .icon-container {
    background-color: #F1F5F9;
}

.coming-soon-container {
    background-color: #fff;
    border-radius: 9px;
}

.delete-modal-small-screen {
    /* max-width: 359px; */
    display: flex;
    align-items: end !important;
    justify-content: center;
    height: 100%;
}


.btn-cancel-calendar {
    background-color: #fff;
    color: #0F3CA0;
    border: 0px;
    font-size: 20px;
    width: 100%;
    height: 56px;
    margin-top: 10px;
    border-radius: 14px;
    margin-bottom: 52px;
}

.btn-delete-calendar {
    padding: 1px 35px;
    background-color: #E5E5E6;
    color: #A63E49;
    border: 0px;
    font-size: 20px;
    width: 100%;
    height: 56px;
    border-top: thin solid #B1B1B3;
}

.delete-modal-small-screen .modal-content {
    border: 0px;
    background-color: transparent;
      max-width: 359px;
}

.delete-calendar-modal .modal-header .modal-title {
    font-size: 14px;
    color: #707074;
    font-family: SFBold;
}

.delete-calendar-modal .modal-body {
    font-size: 14px;
    color: #707074;
}

.delete-calendar-btn-no {
    padding: 1px 37px;
    background-color: #fff;
    color: #232526;
    border: 0px;
    font-size: 13px;
    box-shadow: 1px 2px 2px #ccc, -1px 1px 2px #ccc;
}

.delete-calendar-btn-yes {
    padding: 1px 35px;
    background-color: #C70000;
    border: 0px;
    font-size: 13px;
}

.delete-calendar-modal {
    max-width: 343px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding-top: 1rem;
    padding-Left: 1rem;
    padding-Right: 1rem;
    padding-bottom: 1.3rem;
    margin-bottom: 18px;
}


.delete-modal-small-screen .modal-content .delete-modal-content .modal-title {
    font-size: 13px;
    color: #87868B;
    font-family: SFBold;
}

.delete-modal-small-screen .modal-content .delete-modal-content .modal-details {
    font-size: 13px;
    color: #8F8F8F;
    font-family: SFNormal;
}

.delete-modal-small-screen .modal-content .delete-modal-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.delete-modal-small-screen .modal-content .delete-modal-item {
    background-color: #E5E5E6;
    border-radius: 14px;
    overflow: hidden;
}


@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .delete-calendar-btn-no {
        padding: 1px 32px;
    }

    .delete-calendar-btn-yes {
        padding: 1px 30px;
    }

}
