.switch-container{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    border-radius: 9px !important;
    align-items: center;
}
.switch-container .form-switch .form-check-input{
    border-radius: 11px;
    box-shadow: none;
    width: 38px;
    height: 22px;
    cursor: pointer;
    background-origin: border-box;
    border-width: 2px;
}
.switch-container .form-switch{
    padding-left: 0px;
    margin-bottom: 0px;
    align-items: center;
    display: flex;
}
.switch-container .form-switch .form-check-input:checked {
    background-color: #007AFF;
}
.link-container{
    border-radius: 6px;
}
.link-container .list-group-item{
    font-size: 18px;
}
.meeting-item-container{
    background-color: #fff;
    border-radius: 9px;
    position: relative;
}