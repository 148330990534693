.body {
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #efeff4;
}

.btn-restart{
  position: fixed;
    top: 20px;
    right: 21px;
    background-color: #fff;
    border-radius: 9px;
    border: 1px;
    padding: 7px 14px;
    box-shadow: 2px 2px 5px #ccc;
}

