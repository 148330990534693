.home-container {
    height: 100vh;
    display: flex;
}

.panel{
    height: 100%;
    overflow: auto;
    flex-direction: column;
}

.custom-col-1 {
    padding: 18px !important;
    min-width: 330px;
    width: 25%;
}

.custom-col-2 {
    padding: 21px !important;
    min-width: 330px;
    width: 25%;
}

.custom-col-3 {
    padding: 21px !important;
    width: auto;
    flex-grow: 1;
}

@media only screen and (max-width: 992px) {
    .custom-col-1 {
        width: 100%;
        flex-shrink: 0;
    }

    .custom-col-2 {
        width: 100%;
        flex-shrink: 0;
    }

    .custom-col-3 {
        width: 100%;
        flex-shrink: 0;
    }
}