.location-details-container{
    height: 100vh;
}

.location-confirmation-container .form-check label{
    font-size: 15px;
}
.location-confirmation-container .form-check input:checked{
    background-color: #3B99FC;
    border-color: #3B99FC;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    border-radius: 3px;
}
.location-confirmation-container .form-check input{
    cursor: pointer;
}

.location-confirmation-container .form-check{
    margin-bottom: 30px;
}

.location-button-container{
    display: flex;
}

.location-button-container>.btn-cancel {
    background: #e5e5ea;
    border-radius: 6px;
    font-size: 15px;
    border: 0px;
    padding: 10px;
    width: 160px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.location-button-container>.btn-save {
    background: #e5e5ea;
    border-radius: 6px;
    font-size: 15px;
    border: 0px;
    width: 160px;
    color: #0a0afe;
    padding: 10px;
    border: 0px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}
.location-button-container>.btn-save:disabled {
    opacity: 0.5;
}


.location-name-label {
    color: darkgray;
    font-family: robotoNormal;
    font-size: 12px;
}


.txt-location-name {
    border: 0px;
    border-radius: 9px;
    box-shadow: 0px 1px 1px #ccc;
    padding: 10px;
}

.txt-location-participants {
    border: 0px;
    border-radius: 9px;
    box-shadow: 0px 1px 1px #ccc;
    padding: 10px;
    max-width: 447px;
    margin-right: 10px;
}

.participants-content .btn-close-icon{
    cursor: pointer;
}

.participants-container .participants-content {
    margin-bottom: 8px !important;
}

.participants-container > .formik-error{
    font-size: 13px;
    font-family: robotoBold;
}

.participants-container{
    margin-bottom: 12px;
}
