.get-user-info-component{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height:100%;
}
.get-user-info-component div div .lbl-email{
    color: #8E8E93;
    font-family: robotoBold;
    font-size: 12px;
}

.get-user-info-component div div .txt-email{
    border: 0px;
    border-radius: 0px;
    box-shadow: 0px 1px 1px #ccc;
    padding: 10px;
}

.calendar-event-time {
    font-family: robotoBold;
    font-size: 25px;
    margin-bottom: 0px;
}

.event-title{
    color: green;
    font-size: 14px;
    font-family: robotoBold;
}


.email-error{
    font-size: 12px;
}

.slot-item {
    display: flex;
    flex-grow: 1;
    padding: 8px;
    padding-left: 0px;
    cursor: pointer;
}
.slot-item-container {
    background-color: #fff;
    padding-left: 16px;
    margin-bottom: 24px;
}

.slot-icon {
    height: 22px;
    width: 22px;
    background-color: #fff;
}

.slot-title {
    margin-Left: 20px;
    font-family: robotoBold;
    line-height: 1.4;
}